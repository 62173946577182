import React from 'react';

import Layout from '@heureca/shared/components/Layout';

const NotFoundPage = () => (
  <Layout pageTitle="404 - Not found">
    <div>404 - Not found</div>
  </Layout>
);

export default NotFoundPage;
